import { AmbientConditionValues } from '../../models/WorkspaceModels';

export interface menuItemData {
  label: string;
  value: string;
  className?: string;
  icon?: string;
  variant?: string;
  closable?: boolean;
  datarf?: string;
  url: string;
  click: boolean;
  key: string;
  disabled: boolean;
  redirectUrl: string;
  gotoTabonClose: string;
}

export interface TabDetails {
  activeTabs: menuItemData[];
  selectedTab: string;
}

export const workspaceTabsObject: { [key: string]: menuItemData } = {
  efficiencyTabData: {
    label: 'Efficiency calculation',
    className: 'workspace-efficiency-tab',
    value: 'efficiency',
    variant: 'primary-tab',
    closable: true,
    datarf: 'efficiency-tab',
    url: '/workspace/efficiency',
    click: true,
    key: 'eff',
    disabled: false,
    redirectUrl: '/workspace/dimensioning',
    gotoTabonClose: 'selection',
  },
  /* configureTabData: {
    label: 'CONFIGURATION',
    className: 'workspace-config-text',
    value: 'configuration',
    variant: 'primary-tab',
    closable: false,
    datarf: 'configuration-text',
    url: '',
    click: false,
    key: 'configuration',
    disabled: false,
    redirectUrl: '/workspace/dimensioning',
    gotoTabonClose: 'selection',
  },
  configureDriveTabData: {
    label: 'Configure drive',
    className: 'workspace-configdrive-tab',
    value: 'configurationDrive',
    variant: 'primary-tab',
    closable: true,
    datarf: 'configurationdrive-tab',
    url: '/workspace/configdrive',
    click: true,
    key: 'configuration drive',
    disabled: false,
    redirectUrl: '/workspace/dimensioning',
    gotoTabonClose: 'selection',
  }, */
};

export interface AmbientConditionEditModalProps {
  modalOpen: boolean;
  onClose: () => void;
  onConfirmClick: (ambientConditionValues: AmbientConditionValues) => void;
}
