import { useTranslate } from '../../../../i18translate/Hooks';
import StandardPointsTable from './StandardPointsTable';
import { I18 } from '../../../../languages/I18';
import { useEffect, useState } from 'react';
import { RootState } from '../../../../store/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { MotorInputs, MotorResults } from '../../models/EfficiencyModel';
import { useGetMotordataMutation } from '../services/APIEfficiencyService';
import ProgressBar from './ProgressBar';
import CommonLoader from '../../../../components/Loader';
import '../../Reports/styles/styles.scss';
import { MotorLossChart } from './MotorLossesChart';
import {
  setActiveTab,
  setMotorEfficiencyDetails,
} from '../../store/WorkspaceReducer';
import ErrorPage from './ErrorPage';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import notificationManager from '../../../../utils/NotificationManager';
import { useSession } from '../../../../utils/Auth';

export interface ErrorType {
  error: boolean;
  type: string;
}
const MotorEfficiency = () => {
  const efficiencyData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        ?.motorEfficiencyDetails,
  );
  const { t } = useTranslate();
  const { session } = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const [getMotorData] = useGetMotordataMutation();
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const motorData = efficiencyData;
  const dispatch = useDispatch();
  const [errorPage, setErrorPage] = useState({
    error: false,
    type: '',
  } as ErrorType);
  const [retry, setRetry] = useState(false);

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );

  const applicationData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const errorPageButtonClick = () => {
    if (errorPage.type === 'retry') {
      setIsLoading(true);
      setRetry(true);
    } else {
      dispatch(setActiveTab('selection'));
    }
  };
  useEffect(() => {
    let motor =
      dimensionData?.selectMotorResult?.dimension?.dimensionResult
        ?.supplyDriveLoads?.[0].motorUnit.results;
    let mcalcid;
    if (motor && motor?.length > 0) {
      for (let mot of motor) {
        if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
          mcalcid = mot.value;
        }
      }
    }
    if (dimensionData.dimensionDataLoading) {
      setIsLoading(true);
    } else if (motorData?.motorTechnicalData?.calcID !== mcalcid || retry) {
      setIsLoading(true);
      let inputs = {} as MotorInputs;
      if (motor && motor?.length > 0) {
        for (let mot of motor) {
          if (mot.name === 'Catno') {
            inputs.catno = mot.value;
          }
          if (mot.name === 'CatNoOriginal' && mot.value !== '') {
            inputs.catno = mot.value;
          }
          if (mot.name === 'CalcID' || mot.name === 'Calc ID') {
            inputs.calcID = mot.value;
          }
          if (mot.name === 'MEPS') {
            inputs.mEPS = mot.value;
          }
          if (mot.name === 'RtdConnection') {
            inputs.rtdConnection = mot.value;
          }
          if (mot.name === 'Frequency ') {
            inputs.frequency = mot.value;
          }
          if (mot.name === 'Voltage ') {
            inputs.rtdVolt = mot.value;
          }
          if (mot.name === 'Power ') {
            inputs.rtdPower = mot.value;
          }
          if (mot.name === 'Ambient') {
            inputs.ambient = mot.value;
          }
          if (mot.name === 'Ambient') {
            inputs.ambient = mot.value;
          }
          if (mot.name === 'TypeProtection' && mot.value !== '') {
            inputs.typeProtection = mot.value;
          }
          if (mot.name === 'VarientCode' && mot.value !== '') {
            inputs.variantCode = mot.value;
          }
        }
      }

      if (!Boolean(inputs.mEPS)) {
        inputs.mEPS = 'NOT DEFINED';
      }

      getMotorData(inputs)
        .then((response) => {
          setRetry(false);
          if (response.data && response?.data !== null) {
            let res: MotorResults | undefined = response?.data;
            if (res?.motorStandardData !== null) {
              setIsLoading(false);
              dispatch(setMotorEfficiencyDetails(response.data));
            } else {
              setIsLoading(false);
              setErrorPage({ error: true, type: 'no data' });
            }
          } else {
            setIsLoading(false);
            setErrorPage({ error: true, type: 'retry' });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorPage({ error: true, type: 'retry' });
        });
    }
  }, [
    dimensionData.dimensionOutputData,
    dimensionData.dimensionDataLoading,
    retry,
  ]);
  const updateProjectApiCall = () => {
    if (window.sessionStorage.getItem('id')) {
      updateProject({
        userToken: session ? session?.user.access_token : undefined,
        projectData: {
          projectId:
            (projectDetails.projectId || window.sessionStorage.getItem('id')) ??
            '',
          projectName:
            (projectDetails.projectName ||
              window.sessionStorage.getItem('name')) ??
            '',
          jsonFileName: applicationData.id,
          projectJson: JSON.stringify({
            dimensionSavedData: {
              dimensionInput:
                applicationData?.dimensionDetails?.selectDriveResult?.dimension
                  ?.dimensionInput?.selectionInputs?.[0],
              applicationOutput: {
                supplyDriveLoads:
                  applicationData?.dimensionDetails?.selectDriveResult
                    ?.dimension?.dimensionResult?.supplyDriveLoads,
              },
              exceptionType:
                applicationData?.dimensionDetails?.selectDriveResult
                  ?.exceptionType,
              message:
                applicationData?.dimensionDetails?.selectDriveResult?.message,
            },
            selectionSavedData: {
              industryName: applicationData?.selectionDetails?.industryName,
              applicationName:
                applicationData?.selectionDetails?.applicationName,
            },
            efficiencySavedData: {
              motorId:
                applicationData?.motorEfficiencyDetails?.motorTechnicalData
                  ?.motorId, //motorTechnicalData
              driveTypeCode: '', //driveTypeCode
              udpPoints: [],
              driveFrame: '', //
            },
            plusOptions: applicationData?.drivePlusCodeObject?.longtypeCode
              ? {
                  longtypeCode:
                    applicationData?.drivePlusCodeObject?.longtypeCode,
                  options: applicationData?.drivePlusCodeObject?.options,
                }
              : null,
          }),
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId: applicationData?.id,
        },
      })
        .then((res) => {})
        .catch((error) => {
          notificationManager.error(
            'Unable to autosave the project due to: ' + error.toString(),
          );
        });
    }
  };

  useEffect(() => {
    if (
      applicationData?.motorEfficiencyDetails &&
      Object.keys(applicationData?.motorEfficiencyDetails).length > 0
    ) {
      updateProjectApiCall();
    }
  }, [applicationData?.motorEfficiencyDetails]);

  return (
    <div className="efficiency-container">
      {isLoading && (
        <CommonLoader
          size="xlarge"
          indicatorType={'circular'}
          progressType={'indeterminate'}
          progressValue={50}
          type={'primary-black'}
        />
      )}
      {!isLoading &&
        !errorPage.error &&
        motorData?.motorTechnicalData &&
        motorData?.motorTechnicalData?.typeDesignation && (
          <>
            <div
              className="efficiency-info-notification"
              data-rf="motor-losses-page-message"
            >
              <img className="pds-img" src="/infoblue.svg" />
              <div>{t(I18.efficiency_losses_motor_page_notification)}</div>
            </div>
            <div>
              <div data-rf="motor-losses-motor-type-designation">
                {motorData.motorTechnicalData.typeDesignation + ' '}
                {t(I18.efficiency_losses_motor_page_heading)}
              </div>
              <div className="disp-flex efficiency-info-section">
                <div className="half-flex">
                  <div
                    className="ProductInfoContainer KeyValRow "
                    data-rf="motor-losses-ieclass"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      <img src="./assets/icon/motor-32.svg" />
                      {t(I18.efficiency_losses_data_motor_ieclass)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {motorData.motorTechnicalData.efficiencyClass}
                    </div>
                  </div>
                  <br />
                  <div className="hr" />
                  <br />
                  <div data-rf="motor-losses-progress-bar">
                    <ProgressBar
                      bigText={true}
                      label={'Motor efficiency with VSD supply'}
                      progress={motorData.motorTechnicalData.efficiency}
                    />
                  </div>
                  <div className="hr mt-10" />
                </div>
                <div className="half-flex">
                  <strong>
                    {t(I18.efficiency_losses_motor_page_side_note_strong) + ' '}
                  </strong>
                  {t(I18.efficiency_losses_motor_page_side_note)}
                </div>
              </div>
            </div>
            <div className="pds-charts-container">
              <div
                className="efficiency-page-headers mt-40"
                data-rf="motor-losses-chart-heading"
              >
                {t(I18.efficiency_losses_motor_page_graph_header)}
              </div>
              <MotorLossChart
                height={400}
                loading={false}
                type={'large'}
                standardPoints={motorData.motorStandardData}
              />
              <div
                className="efficiency-page-headers"
                data-rf="motor-losses-table-heading"
              >
                {t(I18.efficiency_losses_motor_page_table_header)}
              </div>
              <StandardPointsTable data={motorData.motorStandardData} />
              <div className="loss-determination-section">
                <div className="report-page-loss-subheader">
                  <strong>
                    {t(
                      I18.efficiency_losses_motor_page_loss_determination_heading,
                    )}
                  </strong>
                </div>
                <br />
                <div>
                  <div className="report-page-loss-content">
                    {t(I18.efficiency_losses_motor_page_loss_determination)}
                    <br />
                    {t(I18.efficiency_losses_motor_page_loss_determination_sub)}
                  </div>
                </div>
                <br />
                <br />
                <div className="report-page-loss-subheader">
                  <strong>
                    {t(I18.efficiency_losses_motor_page_disclaimer_heading)}
                  </strong>
                </div>
                <br />
                <div>
                  <div className="report-page-loss-content">
                    {t(I18.efficiency_losses_page_disclaimer)}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {!isLoading && errorPage.error && (
        <ErrorPage
          type={errorPage.type}
          page="motor"
          onClick={() => errorPageButtonClick()}
        />
      )}
    </div>
  );
};

export default MotorEfficiency;
