import React from 'react';

const CustomTabs = ({ label, ...props }: any) => {
  return (
    <div {...props}>
      {/* we can add padding to it*/}
      <div>{label}</div> {props.children}
    </div>
  );
};

export default CustomTabs;
