import { roundDecimal } from '../../../../utils/GeneralUtils';
import {
  MotorLoadData,
  MotorSelectionData,
  ResultData,
} from './MotorSelectionModels';

export const motorTableData: ResultData[] = [
  {
    name: 'Voltage',
    value: '-',
    unit: '',
  },
  {
    name: 'Frequency',
    value: '-',
    unit: '',
  },
  {
    name: 'Power',
    value: '-',
    unit: '',
  },
  {
    name: 'Poles',
    value: '-',
    unit: '',
  },
  {
    name: 'Speed',
    value: '-',
    unit: '',
  },
  {
    name: 'Max mech.speed',
    value: '-',
    unit: '',
  },
  {
    name: 'Current',
    value: '-',
    unit: '',
  },
  {
    name: 'Torque',
    value: '-',
    unit: '',
  },
  {
    name: 'Power factor',
    value: '-',
    unit: '',
  },
  {
    name: 'Efficiency',
    value: '-',
    unit: '',
  },
  {
    name: 'Efficiency class',
    value: '-',
    unit: '',
  },
  {
    name: 'Winding',
    value: '-',
    unit: '',
  },
  {
    name: 'Temperature rise class',
    value: '-',
    unit: '',
  },
  {
    name: 'Insulation class',
    value: '-',
    unit: '',
  },
  {
    name: 'Temperature rise',
    value: '-',
    unit: '',
  },
  {
    name: 'Inertia',
    value: '-',
    unit: '',
  },
];

export const selectionTableData = [
  {
    row: ['Torque [Nm]', '', '', ''],
  },
  {
    row: ['n min', '-', '-', '-'],
  },
  {
    row: ['n base', '-', '-', '-'],
  },
  {
    row: ['Power [kW]', '', '', ''],
  },
  {
    row: ['n min', '-', '-', '-'],
  },
  {
    row: ['n base', '-', '-', '-'],
  },
  {
    row: ['Overload [Nm]', '', '', ''],
  },
  {
    row: ['n min', '-', '-', '-'],
  },
  {
    row: ['n base', '-', '-', '-'],
  },
];

export const vsdRatingTableData = {
  vSDData: [
    {
      row: ['Voltage', '-', '-', '-'],
    },
    {
      row: ['Frequency', '-', '-', '-'],
    },
    {
      row: ['Output power', '-', '-', '-'],
    },
    {
      row: ['Speed', '-', '-', '-'],
    },
    {
      row: ['Current', '-', '-', '-'],
    },
    {
      row: ['Load torque', '-', '-', '-'],
    },
  ],
};

export const motorFormDataDefaults: MotorSelectionData = {
  motorName: '',
  meps: '6',
  frameMaterial: '0',
  motorFamily: '0',
  poleNumber: '0',
  terminalBoxLocation: '0',
  efficiencyClass: '0',
  winding: '0',
  design: '1',
  connection: '0',
  ipClass: '2',
  icClass: '0',
  imClass: '0',
  maxSpeedRule: '800',
  insulation: '0',
  tempRiseClass: '1',
  tempReserve: '1',
  motorTmaxMargin: '43',
  marineClassification: undefined,
  motorsCount: '1',
  motorType: '1',
};

export const motorLoadDataGPDDefaults: MotorLoadData = {
  loadType: '1',
  overLoadType: '0',
  nMin: '400',
  nMax: '1500',
  nBase: '1500',
  nUnit: 'rpm',
  powerMin: '0',
  power: '0',
  powerMax: '0',
  powerUnit: 'kW',
  olBaseInput: '100',
  olMaxInput: '100',
  olUnit: '%',
  maxLoadTime: '60',
  dutyCycleTime: '600',
  oneTimeOverload: '100',
  oneTimeOverloadTime: '2',
  oneTimeOverloadMaxSpeed: '400',
};

export const motorLoadDataIndustrialDriveDefaults: MotorLoadData = {
  loadType: '1',
  overLoadType: '0',
  nMin: '300', //300
  nMax: '1500',
  nBase: '1500',
  nUnit: 'rpm',
  powerMin: '0',
  power: '0',
  powerMax: '0',
  powerUnit: 'kW',
  olBaseInput: '100',
  olMaxInput: '100',
  olUnit: '%',
  maxLoadTime: '10',
  dutyCycleTime: '600',
  oneTimeOverload: '100',
  oneTimeOverloadTime: '2',
  oneTimeOverloadMaxSpeed: '300',
};

export const motorLoadDataMachineryDrivesDefaults: MotorLoadData = {
  loadType: '1',
  overLoadType: '0',
  nMin: '400',
  nMax: '1500',
  nBase: '1500',
  nUnit: 'rpm',
  powerMin: '0',
  power: '0',
  powerMax: '0',
  powerUnit: 'kW',
  olBaseInput: '100',
  olMaxInput: '100',
  olUnit: '%',
  maxLoadTime: '60',
  dutyCycleTime: '600',
  oneTimeOverload: '100',
  oneTimeOverloadTime: '2',
  oneTimeOverloadMaxSpeed: '400',
};

export const motorLoadDataHVACAndWaterDefaults: MotorLoadData = {
  loadType: '3',
  overLoadType: '0',
  nMin: '1500',
  nMax: '1500',
  nBase: '1500',
  nUnit: 'rpm',
  powerMin: '0',
  power: '0',
  powerMax: '0',
  powerUnit: 'kW',
  olBaseInput: '100',
  olMaxInput: '100',
  olUnit: '%',
  maxLoadTime: '60',
  dutyCycleTime: '600',
  oneTimeOverload: '100',
  oneTimeOverloadTime: '2',
  oneTimeOverloadMaxSpeed: '400',
};

export const motorLoadPropertiesData = {
  speed: {
    min: 300,
    base: 1500,
    max: 1500,
    unit: 'rpm',
  },
  power: {
    min: 0,
    base: 0,
    max: 0,
    unit: 'kW',
  },
  overLoad: {
    min: null,
    base: 100,
    max: 100,
    unit: '%',
  },
};

export const handleOnLoadTypeChange = (
  event: string,
  loadFormData: MotorLoadData,
  projectType: number,
) => {
  let loadfields: MotorLoadData = { ...loadFormData };
  switch (event) {
    case '0': //Constant power
      if (projectType.toString() === '5') {
        //GPD
        loadfields['nMin'] = loadFormData.nBase;
        loadfields['maxSpeed'] = loadFormData.nBase; //maxSpeed is OLtime, it should be equal to nmin
      }
      break;
    case '1': //Constant torque
      if (projectType.toString() === '5') {
        loadfields['nMin'] = '400';
        loadfields['olMaxInput'] = '100';
        loadfields['nMax'] = loadFormData.nBase;
        loadfields['maxSpeed'] = '400';
      }
      break;
    case '2': //Const. torque/power
      if (projectType.toString() === '5') {
        loadfields['maxSpeed'] = '1500';
      }
      break;
    case '3': //Pump/fan load
    case '4': //Refrigerator/chiller
    case '5': //Compressor/blower
      loadfields['nMin'] = loadFormData.nBase;
      loadfields['nMax'] = loadFormData.nBase;
      loadfields['olMaxInput'] = '100';
      loadfields['maxSpeed'] = loadFormData.nBase;
      break;
  }
  loadfields.powerMin =
    parseFloat(loadFormData.power) > 0
      ? roundDecimal(
          (parseFloat(loadfields.nMin) / parseFloat(loadfields.nBase)) *
            parseFloat(loadFormData.power),
        ).toString()
      : '0';

  loadfields.powerMax = loadFormData.power;

  return loadfields;
};

export const handleLoadTableChanges = (
  event: string,
  type: string,
  loadFormData: any,
) => {
  let inputValue = 0;
  if (Boolean(event)) {
    inputValue = parseFloat(event);
  }
  let loadfields = { ...loadFormData };
  switch (type) {
    case 'nMin':
      if (
        loadFormData.loadType === '1' ||
        loadFormData.loadType === '2' ||
        loadFormData.loadType === '4'
      ) {
        loadfields['nMin'] = inputValue.toString();
      }
      break;
    case 'nBase':
      if (inputValue <= 5000) {
        loadfields['nBase'] = inputValue.toString();
        if (loadFormData.loadType === '1' || loadFormData.loadType === '3') {
          loadfields['nMax'] = inputValue.toString();
        }
        if (loadFormData.loadType === '0' || loadFormData.loadType === '3') {
          loadfields['nMin'] = inputValue.toString();
        }
      } else {
        loadfields['nBase'] = '0';
        if (loadFormData.loadType === '1' || loadFormData.loadType === '3') {
          loadfields['nMax'] = '0';
        }
        if (loadFormData.loadType === '0' || loadFormData.loadType === '3') {
          loadfields['nMin'] = '0';
        }
      }
      break;
    case 'nMax':
      if (inputValue <= 40000) {
        loadfields['nMax'] = inputValue.toString();
      }
      break;
    case 'power':
      if (inputValue <= 9999 && inputValue >= 0) {
        if (inputValue === 0) {
          loadfields['overLoadType'] = '0';
          loadfields[type] = inputValue.toString();
          loadfields['powerMax'] = '0';
          loadfields['powerMin'] = '0';
        } else {
          loadfields.powerMin =
            inputValue > 0
              ? roundDecimal(
                  (parseFloat(loadfields.nMin) / parseFloat(loadfields.nBase)) *
                    inputValue,
                ).toString()
              : '0';

          loadfields['power'] = inputValue.toString();
          loadfields['powerMax'] = inputValue.toString();
        }
      } else {
        loadfields['powerMax'] = '0';
        loadfields['powerMin'] = '0';
        loadfields['power'] = inputValue.toString();
      }
      break;
    case 'olMaxInput':
      if (inputValue <= 999) {
        loadfields['olMaxInput'] = inputValue.toString();
      }
      break;
    default:
      loadfields[type] = inputValue.toString();
  }

  return loadfields;
};

export const handleOnMotorTypeChange = (
  motorType: string,
  fields: MotorSelectionData,
) => {
  switch (motorType) {
    case '0':
      //existing induction
      fields['marineClassification'] = '0';
      fields['motorFamily'] = '0';
      break;
    case '1':
      //IEC34 motors
      fields['motorFamily'] = '0'; // Not specified
      fields['tempRiseClass'] = '1'; //B(<80K)
      break;
    case '6':
      //Marine motors
      fields['tempRiseClass'] = '0'; // Not specified
      fields['meps'] = '0'; // Not specified
      fields['maxSpeedRule'] = '100';
      fields['marineClassification'] = '0';
      fields['motorFamily'] = '0'; // Not specified
      break;
    case '7':
      // NEMA motors
      fields['meps'] = '0'; // Not specified
      fields['motorFamily'] = '0'; //Not specified
      fields['tempRiseClass'] = '2'; // F(<105K)
      fields['tempReserve'] = '0'; // Keep
      break;
    case '9':
      // Flameproof
      fields['maxSpeedRule'] = '100'; // Standard
      fields['motorFamily'] = '14'; // Ex db eb IIB T4 Gb
      break;
    case '21':
      // Increased Safety
      fields['motorFamily'] = '26'; // Ex ec IIC T3 Gc
      fields['maxSpeedRule'] = '100'; // Standard
      break;
    case '11':
      // Dust Ignition protection
      fields['motorFamily'] = '17';
      fields['maxSpeedRule'] = '100'; // Standard
      break;
    case '19':
      // SynRM
      fields['motorFamily'] = '0';
      fields['meps'] = '0';
      fields['design'] = '3'; // High efficiency
      fields['maxSpeedRule'] = '100'; // Standard
      break;
    case '15':
      //low inertia hdp
      fields['meps'] = '0';
      fields['icClass'] = '9'; // Not specified
      fields['maxSpeedRule'] = '400'; // Standard ball bearing
      break;
    case '26': //Roller table motors
      fields['motorFamily'] = '33';
      fields['icClass'] = '10';
      break;
    default:
      break;
  }
  return fields;
};

export const displayMotorCatalogueTabValues = (
  name: string,
  motorType?: string,
) => {
  if (
    name === 'Voltage' ||
    name === 'Frequency' ||
    name === 'Power' ||
    name === 'Poles' ||
    name === 'Speed' ||
    name === 'Max mech.speed' ||
    name === 'Current' ||
    name === 'Torque' ||
    name === 'Tmax/Tn' ||
    name === 'Power factor' ||
    name === 'Efficiency' ||
    name === 'Efficiency class' ||
    name === 'MEPS' ||
    name === 'Winding' ||
    name === 'Temperature rise class' ||
    name === 'Insulation class' ||
    name === 'Temperature rise' ||
    name === 'Inertia' ||
    name === 'ID CODE' ||
    name === 'Calc ID' ||
    name === 'NEMA design letter' ||
    name === 'I acc' ||
    name === 'T acc' ||
    name === 'Power factor acc'
  ) {
    if (name === 'NEMA design letter' && motorType === '7') {
      return true;
    } else if (name === 'NEMA design letter' && motorType !== '7') {
      return false;
    }
    return true;
  } else return false;
};
