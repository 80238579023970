import {
  CommonuxDatagrid,
  CommonuxDatagridRow,
  CommonuxDatagridDatacell,
  CommonuxDatagridHeader,
} from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { PropsWithChildren } from 'react';

interface DataGridProps extends JSX.CommonuxDatagrid {
  className?: string;
}
interface DataGridRowProps extends JSX.CommonuxDatagridRow {
  className?: string;
}
interface DataGridCellProps extends JSX.CommonuxDatagridDatacell {
  className?: string;
}
interface DataGridHeaderProps extends JSX.CommonuxDatagridHeader {
  clasName?: string;
}

export const DataGrid = (props: PropsWithChildren<DataGridProps>) => {
  return <CommonuxDatagrid {...props}>{props?.children}</CommonuxDatagrid>;
};

export const DataGridHeader = (
  props: PropsWithChildren<DataGridHeaderProps>,
) => {
  return (
    <CommonuxDatagridHeader {...props}>
      {props?.children}
    </CommonuxDatagridHeader>
  );
};

export const DataGridRow = (props: PropsWithChildren<DataGridRowProps>) => {
  return (
    <CommonuxDatagridRow {...props}>{props?.children}</CommonuxDatagridRow>
  );
};

export const DataGridCell = (props: PropsWithChildren<DataGridCellProps>) => {
  return (
    <CommonuxDatagridDatacell {...props}>
      {props?.children}
    </CommonuxDatagridDatacell>
  );
};
