import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import ProgressBar from './ProgressBar';
import { useLazyGetDrivedataQuery } from '../services/APIEfficiencyService';
import { DriveInputs } from '../../models/EfficiencyModel';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import CommonLoader from '../../../../components/Loader';
import { DriveRelativeLossChart } from './DriveRelativeLossChart';
import { DriveLossChart } from './DriveLossesChart';
import DriveStandardPointsTable from './DriveStandardPointTable';
import '../Styles/styles.scss';
import '../Styles/ProductInfo.scss';
import {
  setActiveTab,
  setDriveEfficiencyDetails,
} from '../../store/WorkspaceReducer';
import ErrorPage from './ErrorPage';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { useSession } from '../../../../utils/Auth';
import notificationManager from '../../../../utils/NotificationManager';

export interface ErrorType {
  error: boolean;
  type: string;
}

const DriveEfficiency = () => {
  const { t } = useTranslate();
  const [errorPage, setErrorPage] = useState({
    error: false,
    type: '',
  } as ErrorType);
  const [retry, setRetry] = useState(false);
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const { session } = useSession();

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });

  const efficiencyData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .driveEfficiencyDetails,
  );

  const efficiencyPoint = efficiencyData?.standardData?.filter((x) => {
    if (x.frequency == 90 && x.current == 100) {
      return x;
    }
  });

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );

  const applicationData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank];
  });

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const errorPageButtonClick = () => {
    if (errorPage.type === 'retry') {
      setIsLoading(true);
      setRetry(true);
    } else {
      dispatch(setActiveTab('selection'));
    }
  };
  const [getDrivedata, results] = useLazyGetDrivedataQuery();
  const [isLoading, setIsLoading] = useState(false);
  const driveResult = efficiencyData;
  const dispatch = useDispatch();

  useEffect(() => {
    let drives =
      dimensionData?.selectDriveResult?.dimension?.dimensionResult
        ?.supplyDriveLoads[0]?.inverterUnit?.results;
    let dimDrivecode;
    if (drives && drives?.length > 0) {
      for (let drive of drives) {
        if (drive.name === 'TypeDesignation') {
          dimDrivecode = drive.value;
        }
      }
    }
    if (dimensionData.dimensionDataLoading) {
      setIsLoading(true);
    } else if (
      (dimDrivecode &&
        driveResult?.driveTechnicalData?.driveTypeCode !== dimDrivecode) ||
      retry
    ) {
      setIsLoading(true);
      let inputs = {} as DriveInputs;
      if (drives && drives?.length > 0) {
        for (let drive of drives) {
          if (drive.name === 'TypeDesignation') {
            if (
              drive.value.includes('ACS180-04x') ||
              drive.value.includes('ACH180-04x')
            ) {
              inputs.driveTypeCode = drive.value.replace('04x', '04S');
            } else inputs.driveTypeCode = drive.value;
          }
          if (drive.name === 'FrameType') {
            inputs.driveFrame = drive.value;
          }
        }
      }

      getDrivedata(inputs);
    }
  }, [
    dimensionData.dimensionOutputData,
    dimensionData.dimensionDataLoading,
    retry,
  ]);

  useEffect(() => {
    if (results && results.data && results.status === 'fulfilled') {
      setIsLoading(false);
      let res = results.data;
      if (res?.standardData.length > 0) {
        dispatch(setDriveEfficiencyDetails(results.data));
      } else {
        setErrorPage({ error: true, type: 'no-data' });
      }
    } else if (results.status === 'rejected') {
      setIsLoading(false);
      setErrorPage({ error: true, type: 'retry' });
    }
  }, [results]);

  const updateProjectApiCall = (response: any) => {
    if (window.sessionStorage.getItem('id')) {
      updateProject({
        userToken: session ? session?.user.access_token : undefined,
        projectData: {
          projectId:
            (projectDetails.projectId || window.sessionStorage.getItem('id')) ??
            '',
          projectName:
            (projectDetails.projectName ||
              window.sessionStorage.getItem('name')) ??
            '',
          jsonFileName: applicationData.id,
          projectJson: JSON.stringify({
            dimensionSavedData: {
              dimensionInput:
                applicationData?.dimensionDetails?.selectDriveResult?.dimension
                  ?.dimensionInput?.selectionInputs?.[0],
              applicationOutput: {
                supplyDriveLoads:
                  applicationData?.dimensionDetails?.selectDriveResult
                    ?.dimension?.dimensionResult?.supplyDriveLoads,
              },
              exceptionType:
                applicationData?.dimensionDetails?.selectDriveResult
                  ?.exceptionType,
              message:
                applicationData?.dimensionDetails?.selectDriveResult?.message,
            },
            selectionSavedData: {
              industryName: applicationData?.selectionDetails?.industryName,
              applicationName:
                applicationData?.selectionDetails?.applicationName,
            },
            efficiencySavedData: {
              motorId: '', //motorTechnicalData
              driveTypeCode: response?.driveTechnicalData?.driveTypeCode, //driveTypeCode
              udpPoints: [],
              driveFrame: '', //
            },
            plusOptions: applicationData?.drivePlusCodeObject?.longtypeCode
              ? {
                  longtypeCode:
                    applicationData?.drivePlusCodeObject?.longtypeCode,
                  options: applicationData?.drivePlusCodeObject?.options,
                }
              : null,
          }),
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId: applicationData?.id,
        },
      })
        .then((res) => {})
        .catch((error) => {
          notificationManager.error(
            'Unable to autosave the project due to: ' + error.toString(),
          );
        });
    }
  };

  useEffect(() => {
    if (results && results.data && results.status === 'fulfilled')
      updateProjectApiCall(results.data);
  }, [results]);

  return (
    <div className="efficiency-container">
      {isLoading && (
        <CommonLoader
          size="xlarge"
          indicatorType={'circular'}
          progressType={'indeterminate'}
          progressValue={50}
          type={'primary-black'}
        />
      )}
      {!isLoading &&
        !errorPage.error &&
        driveResult?.driveTechnicalData &&
        driveResult?.driveTechnicalData?.driveTypeCode && (
          <>
            <div
              className="efficiency-info-notification"
              data-rf="drive-losses-page-message"
            >
              <img className="pds-img" src="/infoblue.svg" />
              <div>{t(I18.efficiency_losses_drive_page_notification)}</div>
            </div>
            <div>
              <div data-rf="drive-losses-drive-typecode-header">
                {driveResult.driveTechnicalData.driveTypeCode}{' '}
                {t(I18.efficiency_losses_drive_page_standard)}
              </div>
              <div className="disp-flex efficiency-info-section">
                <div className="half-flex-chart">
                  <div
                    className="ProductInfoContainer KeyValRow"
                    data-rf="drive-losses-iesclass"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      <img src="./assets/icon/drive-32.svg" />
                      {t(I18.efficiency_losses_drive_page_ieclass_heading)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {driveResult.driveTechnicalData.ieClass}
                    </div>
                  </div>
                  <br />
                  <div className="hr" />
                  <br />

                  <div
                    className="ProductInfoContainer KeyValRow"
                    data-rf="drive-losses-relative-losses"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      {t(I18.efficiency_losses_data_relative_losses)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {efficiencyPoint &&
                        efficiencyPoint.length > 0 &&
                        efficiencyPoint[0].relativeLoss +
                          ' ' +
                          efficiencyPoint[0].relativeLossUnit}
                    </div>
                  </div>
                  <br />
                  <div className="hr" />
                  <br />

                  <div
                    className="ProductInfoContainer KeyValRow"
                    data-rf="drive-losses-absolute-losses"
                  >
                    <div className="ProductInfoContainer KeyValKey fs-16">
                      {t(I18.efficiency_losses_data_absolute_losses)}
                    </div>
                    <div className="ProductInfoContainer KeyValValue fs-16">
                      {efficiencyPoint &&
                        efficiencyPoint.length > 0 &&
                        efficiencyPoint[0].absoluteLoss +
                          ' ' +
                          efficiencyPoint[0].absoluteLossUnit}
                    </div>
                  </div>
                  <br />
                  <div className="hr" />
                  <br />

                  <div style={{ marginBottom: '8px' }}>
                    <div data-rf="drive-losses-progress-bar">
                      <ProgressBar
                        bigText={true}
                        label={'Efficiency'}
                        progress={efficiencyPoint?.[0]?.efficiency}
                      />
                    </div>
                    <br />
                    <div className="hr" />
                    <br />

                    <DriveRelativeLossChart
                      loading={isLoading}
                      results={driveResult}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="hr" />
                </div>
                <div className="half-flex-info">
                  <div className="ProductInfoContainer Description">
                    <div>
                      <strong>
                        {t(I18.efficiency_losses_data_cdm_description_strong) +
                          ' '}
                      </strong>
                      {t(I18.efficiency_losses_data_cdm_description)}
                      <br />
                      <br />
                      {t(I18.efficiency_losses_data_cdm_description_sub)}
                    </div>
                    <br />
                    <br />
                    <div>
                      <strong>
                        {t(
                          I18.efficiency_losses_drive_page_relative_loss_description_strong,
                        ) + ' '}
                      </strong>
                      {t(
                        I18.efficiency_losses_drive_page_relative_loss_description,
                      )}
                    </div>
                    <br />
                    <br />
                    <div>
                      <strong>
                        {t(
                          I18.efficiency_losses_drive_page_efficiency_of_cdm_description_strong,
                        ) + ' '}
                      </strong>
                      {t(
                        I18.efficiency_losses_drive_page_efficiency_of_cdm_description,
                      )}
                    </div>
                    <br />
                    <br />
                    <div>
                      {t(
                        I18.efficiency_losses_drive_page_efficiency_of_cdm_description_sub1,
                      )}
                    </div>
                    <br />
                    <br />
                    <div>
                      {t(
                        I18.efficiency_losses_drive_page_efficiency_of_cdm_description_sub2,
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="pds-charts-container">
                <div className="losses-chart">
                  <div
                    className="efficiency-page-headers"
                    data-rf="drive-losses-chart-heading"
                  >
                    {t(I18.cdm_absolute_losses_heading)}
                  </div>
                  <DriveLossChart
                    height={400}
                    loading={isLoading}
                    type="large"
                    standardPoints={driveResult.standardData}
                    partloadPoints={[]}
                    nominalFrequency={
                      driveResult?.driveTechnicalData?.nominalFrequencyRange
                    }
                  />
                </div>
                <div className="operating-points-table">
                  <div
                    className="efficiency-page-headers"
                    data-rf="drive-losses-table-heading"
                  >
                    {t(
                      I18.efficiency_losses_drive_page_standard_points_table_heading,
                    )}
                  </div>
                  <DriveStandardPointsTable
                    data={driveResult.standardData}
                    losses={driveResult.standBy + ' ' + driveResult.standByUnit}
                  />
                </div>
                <div className="report-page-loss-subheader">
                  <strong>
                    {t(I18.efficiency_cdm_loss_determination_header)}
                  </strong>
                </div>
                <div className="report-page-loss-content">
                  <p>
                    {t(I18.efficiency_losses_drive_page_cdm_loss_determination)}
                  </p>
                  <p>{t(I18.efficiency_losses_drive_page_cdm_loss_heading)}</p>
                  <ol>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1)}
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_a)}
                      <sub>
                        {t(I18.efficiency_losses_drive_page_cdm_loss_1_a_sub)}
                      </sub>{' '}
                      ={' '}
                      <strong>
                        {efficiencyData?.driveTechnicalData?.inputFrequency
                          ? efficiencyData?.driveTechnicalData?.inputFrequency
                          : '-'}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_b)}
                      <sub>
                        {t(I18.efficiency_losses_drive_page_cdm_loss_1_b_sub)}
                      </sub>
                      =
                      <strong>
                        {efficiencyData?.driveTechnicalData?.inputVoltage
                          ? efficiencyData?.driveTechnicalData?.inputVoltage
                          : '-'}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_c)}
                      <sub>
                        {' '}
                        {t(I18.efficiency_losses_drive_page_cdm_loss_1_c_sub)}
                      </sub>{' '}
                      ={' '}
                      <strong>
                        {' '}
                        {t(
                          I18.efficiency_losses_drive_page_cdm_loss_1_c_strong,
                        )}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_d)}
                      <sub>
                        {t(I18.efficiency_losses_drive_page_cdm_loss_1_d_sub)}
                      </sub>{' '}
                      ={' '}
                      <strong>
                        {' '}
                        {efficiencyData?.driveTechnicalData?.outputFrequency
                          ? efficiencyData?.driveTechnicalData?.outputFrequency
                          : '-'}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_e)}
                      <sub>
                        {t(I18.efficiency_losses_drive_page_cdm_loss_1_e_sub)}
                      </sub>{' '}
                      ={' '}
                      <strong>
                        {efficiencyData?.driveTechnicalData?.outputVoltage
                          ? efficiencyData?.driveTechnicalData?.outputVoltage
                          : '-'}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_f)}
                      <strong>
                        {efficiencyData?.driveTechnicalData?.dcLinkVoltage
                          ? efficiencyData?.driveTechnicalData?.dcLinkVoltage +
                            ' ' +
                            efficiencyData?.driveTechnicalData
                              ?.dcLinkVoltageUnit
                          : '-'}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_g)}{' '}
                      <strong>
                        {efficiencyData?.driveTechnicalData
                          ?.switchingFrequencySupplySide
                          ? efficiencyData?.driveTechnicalData
                              ?.switchingFrequencySupplySide +
                            ' ' +
                            efficiencyData?.driveTechnicalData
                              ?.switchingFrequencySupplySideUnit
                          : '-'}
                      </strong>
                      <br />
                      {t(I18.efficiency_losses_drive_page_cdm_loss_1_h)}
                      <strong>
                        {efficiencyData?.driveTechnicalData
                          ?.switchingFrequencyInverterSide
                          ? efficiencyData?.driveTechnicalData
                              ?.switchingFrequencyInverterSide +
                            ' ' +
                            efficiencyData?.driveTechnicalData
                              ?.switchingFrequencyInverterSideUnit
                          : '-'}
                      </strong>
                      <br />
                    </li>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_2)}
                      <sub>n</sub>{' '}
                      {t(I18.efficiency_losses_drive_page_cdm_loss_2_sub)}
                      <sub>N</sub> * U​<sub>1,out</sub>
                      <br />
                    </li>
                    <li>
                      {' '}
                      {t(I18.efficiency_losses_drive_page_cdm_loss_3)} <br />
                    </li>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_4)}
                      <br />
                    </li>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_5)}
                      <br />
                    </li>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_6)}
                      <br />
                    </li>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_7)}
                      <br />
                    </li>
                    <li>
                      {t(I18.efficiency_losses_drive_page_cdm_loss_8)}P ​
                      <sub>output, CDM</sub> / ( P <sub>​output, CDM</sub> + P{' '}
                      <sub>​losses, CDM</sub>). P <sub>​output, CDM</sub> is
                      {' ' +
                        t(
                          I18.efficiency_losses_drive_page_cdm_loss_8_output,
                        )}{' '}
                      <sub>​losses, CDM</sub>{' '}
                      {t(
                        I18.efficiency_losses_drive_page_cdm_loss_8_output_end,
                      )}
                      <br />
                    </li>
                  </ol>
                  <p>
                    {t(I18.efficiency_losses_drive_page_cdm_loss_conclusion)}
                  </p>
                </div>
                <div className="report-page-loss-subheader">
                  <strong>
                    {t(I18.efficiency_losses_motor_page_disclaimer_heading)}
                  </strong>
                </div>
                <br />
                <div>
                  <div className="report-page-loss-content">
                    {t(I18.efficiency_losses_page_disclaimer)}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      {!isLoading && errorPage.error && (
        <ErrorPage
          type={errorPage.type}
          page="drive"
          onClick={() => errorPageButtonClick()}
        />
      )}
    </div>
  );
};

export default DriveEfficiency;
