import { CommonuxButton } from '@abb-motion-ux/commonux-web-components-react';
import { JSX } from '@abb-motion-ux/commonux-web-components/dist/types';
import { MouseEventHandler, PropsWithChildren } from 'react';

interface ButtonProps extends JSX.CommonuxButton {
  className?: string;
  onClick?: MouseEventHandler<any>;
  loading?: boolean;
  slot?: string;
  id?: string;
}

function Button(props: PropsWithChildren<ButtonProps>) {
  return <CommonuxButton {...props}>{props?.children}</CommonuxButton>;
}

export default Button;
