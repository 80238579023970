import { useEffect, useLayoutEffect, useRef, useState, memo } from 'react';
import Button from '../../../../components/Form/Button';
import Icon from '../../../../components/Icon';
import SelectContainer from '../../../../components/Form/SelectContainer';
import SelectOption from '../../../../components/Form/SelectOption';
import Select from '../../../../components/Form/Select';
import {
  DropdownData,
  MotorSelectionData,
  MotorSelectionFormProps,
  MotorLoadData,
  Dimension,
  LabelValue,
  MotorSelectionParamResult,
} from '../models/MotorSelectionModels';
import Input from '../../../../components/Form/Input';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  deleteSessionStorageValue,
  generateKey,
} from '../../../../utils/GeneralUtils';
import {
  handleOnLoadTypeChange,
  motorLoadDataGPDDefaults,
  motorLoadDataHVACAndWaterDefaults,
  motorLoadDataIndustrialDriveDefaults,
  motorLoadDataMachineryDrivesDefaults,
} from '../models/MotorDefaultValues';
import { LoadDataMotorSelection } from '../models/DutyCycleModels';
import notificationManager from '../../../../utils/NotificationManager';
import DutyCycleModalMotor from '../Components/DutyCycleModalMotor';
import MotorLoadTable from './../Components/MotorLoadTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { useGetDriveSizeParametersQuery } from '../services/APIDimensioningService';
import {
  CommonuxLoadingIndicator,
  CommonuxMenu,
  CommonuxMenuItem,
} from '@abb-motion-ux/commonux-web-components-react';
import { PI } from '../../../../utils/Constants';
import { useNavigate } from 'react-router-dom';
import {
  motorFormDataSave,
  motorLoadDataSave,
  saveDutyCycleValuesMotor,
  motorParamsDataSave,
  setHasFormDataChanged,
  setHasDimensionDataFetched,
  setHasMultipleOverload,
  driveLoadDataSave,
  resetHasMultipleOverload,
  resetApplication,
  resetMotor,
  setActiveComponent,
  setEfficiencyTabStatus,
  resetApplicationForSelection,
  resetDrive,
  resetDriveErrorResponse,
  resetMotorErrorResponse,
  resetDriveDropdown,
} from '../../store/WorkspaceReducer';
import ManualSelectionModalMotor from './ManualSelectionModalMotor';
import { DriveLoadData } from '../models/DriveSelectionModels';
import InformationModal from '../../../Home/Components/InformationModal';
import { useUpdateProjectMutation } from '../../../Home/services/APIMainService';
import { useSession } from '../../../../utils/Auth';
import {
  useGetCountriesQuery,
  useGetDrivetypeInputsDataMutation,
} from '../../../Home/services/APICommonService';
import { usePostPlusCodeDataByIdMutation } from '../../services/DrivePluscodeService';

const MotorForm = ({
  isFormLoading,
  viewManualSelection,
  onManualSelectionClose,
  isManualSelectionLoading,
  onManualSelectClick,
}: MotorSelectionFormProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasLoadDataChanged, setHasLoadDataChanged] = useState(false);
  const [showMotorModal, setShowMotorModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isApplicationModal, setIsApplicationModal] = useState(true);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [hasDutyCyclePreviousData, setHasDutyCyclePreviousData] =
    useState(false);
  const [updateProject] = useUpdateProjectMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [updatePlusCode] = usePostPlusCodeDataByIdMutation({
    fixedCacheKey: 'shared-update-post',
  });
  const [baseLoadData, setBaseLoadData] = useState<LoadDataMotorSelection[]>([
    {
      rowName: 'BaseLoad',
      time: 0,
      load: 0,
      maxSpeed: 0,
      minSpeed: 0,
      torque: 0,
      power: 0,
    },
  ]);
  const [viewDutyCycle, setViewDutyCycle] = useState(false);
  const [hasOverloadTypeChanged, setHasOverloadTypeChanged] = useState(false);
  const [hasMotorFormDataChanged, setHasMotorFormDataChanged] = useState(false);
  const [torque, setTorque] = useState(0);
  const { session } = useSession();
  const [driveTypeInputData] = useGetDrivetypeInputsDataMutation();

  const { data: countriesData } = useGetCountriesQuery('');

  const projectDetails = useSelector(
    (state: RootState) => state.home.projectData,
  );

  const selectedTransformerDatas = useSelector(
    (state: RootState) =>
      state.workspace.transformerDetails.selectedTransformerDatas
        ?.transformerInput,
  );

  const selectionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      .selectionDetails;
  });

  const projectMotorType = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails,
  );

  const updatedLoadFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorLoadData,
  );

  const updatedFormFields = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.motorFormData,
  );
  const driveFormData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.driveFormData,
  );
  const typeIds = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.typeIds,
  );
  const workspace = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank],
  );

  const selectedMotorData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank].motor,
  );

  const motorResultData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectMotorResult,
  );
  const hasMultipleOverload = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.hasMultipleOverload,
  );
  const ambientConditionValues = useSelector(
    (state: RootState) => state.workspace.ambientConditionValues,
  );
  const {
    data: motorAndDriveData,
    isLoading,
    isFetching,
  } = useGetDriveSizeParametersQuery({
    projectType: typeIds.projectType,
    motorType: updatedFormFields.motorType
      ? parseInt(updatedFormFields.motorType)
      : typeIds.motorType,
  });

  const motorInputData = motorAndDriveData?.motorParams?.motorParams;
  const motorLoadDropdownDataSet = useRef<DropdownData[]>([]);
  const motorSelectionDropdownDataSet = useRef<DropdownData[]>([]);
  const previousDutyCycleValues = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dutyCycleMotorValues,
  );

  if (!projectMotorType.typeIds.projectType) {
    navigate('/workspace');
  }

  useEffect(() => {
    if (typeIds?.projectType && Object.keys(updatedLoadFields).length === 0) {
      switch (typeIds?.projectType.toString()) {
        case '3':
          dispatch(motorLoadDataSave(motorLoadDataIndustrialDriveDefaults));
          break;
        case '5':
          dispatch(motorLoadDataSave(motorLoadDataGPDDefaults));
          break;
        case '6':
          dispatch(motorLoadDataSave(motorLoadDataHVACAndWaterDefaults));
          break;
        case '7':
          dispatch(motorLoadDataSave(motorLoadDataMachineryDrivesDefaults));
          break;
      }
    }
  }, []);

  useLayoutEffect(() => {
    const userMeps = countriesData?.find(
      (country) => country.code === projectDetails.destinationCountry,
    )?.meps;
    if (motorInputData) {
      let motorLoadDropdownData: DropdownData[] = [];
      let motorSelectionDropdownData: DropdownData[] = [];
      let motorSelectionData = {
        efficiency: '',
        type: '',
        family: '',
        meps: '',
      };
      motorInputData?.forEach((inputItem) => {
        inputItem.options.forEach((option) => {
          if (
            option.isDefault &&
            (!!!updatedFormFields[
              inputItem.parameterName as keyof MotorSelectionData
            ] ||
              hasMotorFormDataChanged)
          ) {
            dispatch(
              motorFormDataSave({
                type: inputItem.parameterName,
                event: option.value,
              }),
            );
          }

          if (
            inputItem.parameterName === 'meps' &&
            (!!!updatedFormFields[
              inputItem.parameterName as keyof MotorSelectionData
            ] ||
              hasMotorFormDataChanged) &&
            !!userMeps
          ) {
            inputItem.options?.forEach((option) => {
              if (
                option.label?.trim().replace(/ +/g, '') === userMeps ||
                option.label === userMeps
              ) {
                dispatch(
                  motorFormDataSave({
                    type: inputItem.parameterName,
                    event: option.value,
                  }),
                );
              }
            });
          }
        });

        if (
          inputItem.parameterName === 'loadType' ||
          inputItem.parameterName === 'overLoadType'
        ) {
          motorLoadDropdownData = [
            ...motorLoadDropdownData,
            {
              label:
                inputItem.parameterName === 'loadType'
                  ? 'Load type'
                  : 'Overload type',
              accessor: inputItem.parameterName,
              isMultiSelect: false,
              isVisible: true,
              selectInput:
                inputItem.parameterName === 'loadType'
                  ? getLoadTypeInputs(inputItem?.options)
                  : inputItem.options,
            },
          ];
        } else if (
          inputItem.parameterName === 'motorType' ||
          inputItem.parameterName === 'meps' ||
          inputItem.parameterName === 'frameMaterial' ||
          inputItem.parameterName === 'motorFamily' ||
          inputItem.parameterName === 'poleNumber' ||
          inputItem.parameterName === 'terminalBoxLocation' ||
          inputItem.parameterName === 'efficiencyClass' ||
          inputItem.parameterName === 'winding' ||
          inputItem.parameterName === 'design' ||
          inputItem.parameterName === 'connection' ||
          inputItem.parameterName === 'ipClass' ||
          inputItem.parameterName === 'icClass' ||
          inputItem.parameterName === 'imClass' ||
          inputItem.parameterName === 'maxSpeedRule' ||
          inputItem.parameterName === 'insulation' ||
          inputItem.parameterName === 'tempRiseClass' ||
          inputItem.parameterName === 'tempReserve' ||
          inputItem.parameterName === 'motorTmaxMargin' ||
          inputItem.parameterName === 'marineClassification' ||
          inputItem.parameterName === 'ProductionUnit'
        ) {
          if (
            selectedMotorData &&
            !hasMotorFormDataChanged &&
            updatedFormFields?.['motorType'] !== '6'
          ) {
            if (inputItem.parameterName === 'motorFamily') {
              inputItem.options?.forEach((option) => {
                if (
                  option.label?.toLowerCase() ===
                  selectedMotorData?.motorFamily?.toLowerCase()
                ) {
                  motorSelectionData.family = option.value;
                }
              });
            } else if (inputItem.parameterName === 'efficiencyClass') {
              if (inputItem.options?.length === 1)
                motorSelectionData.efficiency = inputItem.options[0].value;
            } else if (inputItem.parameterName === 'motorType') {
              inputItem.options?.forEach((option) => {
                if (
                  option.label?.toLowerCase() ===
                  selectedMotorData.motorType?.toLowerCase()
                ) {
                  motorSelectionData.type = option.value;
                }
              });
            }
          } else {
            motorSelectionData = {
              efficiency: updatedFormFields?.efficiencyClass,
              family: updatedFormFields?.motorFamily,
              meps: updatedFormFields?.meps,
              type: updatedFormFields?.motorType,
            };
          }

          let motorTypeOptions: LabelValue[] = [];
          if (inputItem.parameterName === 'motorType') {
            inputItem.options.forEach((item) => {
              //Hiding Exisitng Induction motors , HXR, AMI, NXR from dropdown of motors list for Beta & MVP
              if (item.value !== '0' && item.value !== '4') {
                motorTypeOptions = [...motorTypeOptions, item];
              }
            });
          }

          motorSelectionDropdownData = [
            ...motorSelectionDropdownData,
            {
              label:
                inputItem.parameterName !== 'motorType'
                  ? inputItem.parameterLabel
                  : 'Motor Type',
              accessor: inputItem.parameterName,
              isMultiSelect:
                inputItem.parameterName === 'efficiencyClass' ? true : false,
              isVisible: true,
              selectInput:
                inputItem.parameterName === 'motorType'
                  ? motorTypeOptions
                  : inputItem.options,
            },
          ];
        }
      });
      if (selectedMotorData) {
        dispatch(setHasFormDataChanged(motorSelectionData));
      }
      motorLoadDropdownDataSet.current = motorLoadDropdownData;
      motorSelectionDropdownDataSet.current = motorSelectionDropdownData;

      dispatch(motorParamsDataSave(motorAndDriveData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motorInputData]);

  const getLoadTypeInputs = (options: LabelValue[]): LabelValue[] => {
    let filteredOptions: LabelValue[] = [];
    if (Object.keys(driveFormData).length === 0) {
      filteredOptions = options.filter((option) => option.value !== '5');
    } else {
      switch (driveFormData?.type) {
        case '60':
        case '250':
        case '182':
          filteredOptions = options.filter((option) => option.value === '3');
          break;
        case '70':
        case '190':
          filteredOptions = options.filter((option) => option.value !== '5');
          break;
        case '160':
        case '200':
          filteredOptions = options.filter((option) => option.value !== '4');
          break;
        default:
          filteredOptions = options.filter((option) => option.value !== '5');
          break;
      }
    }
    return filteredOptions;
  };

  //TO DO: Combo select for efficiency
  /*   let efficiencyClassSelectedArray =
    updatedFormFields.efficiencyClass ??
    motorInputData?.filter((i) => {
      i.options.map((j) => {
        if (j.isDefault) {
          return j.value;
        }
      });
    }); */

  const handleFormDataChange = (
    event: string,
    type: string,
    dataType: string,
  ) => {
    dispatch(setHasDimensionDataFetched(false));
    if (dataType === 'form') {
      let formFields = { ...updatedFormFields } as any;
      //TO DO: Combo select for efficiency
      /*let efficiencyClassArray = efficiencyClassSelectedArray && [
        ...efficiencyClassSelectedArray,
      ];
       if (type === 'efficiencyClass') {
        const selectedEfficiencyValue = parseInt(event);
        if (selectedEfficiencyValue > 0) {
          if (
            efficiencyClassArray.includes('0') &&
            efficiencyClassArray?.length === 1
          ) {
            efficiencyClassArray.pop();
          }

          if (efficiencyClassArray.includes(event)) {
            efficiencyClassArray = efficiencyClassArray.filter(
              (item) => item !== event
            );
          } else {
            efficiencyClassArray?.push(event);
          }
        } else if (selectedEfficiencyValue === 0) {
          while (efficiencyClassArray.length > 0) {
            efficiencyClassArray.pop();
          }

          efficiencyClassArray.push(event);
        }
      } */
      dispatch(motorFormDataSave({ type, event }));
      formFields[type] = event;
      if (type === 'motorType') {
        dispatch(resetDriveDropdown(true));
      }
      setHasMotorFormDataChanged(true);
    } else {
      let loadfields = { ...updatedLoadFields } as any;
      if (type === 'loadType') {
        loadfields = handleOnLoadTypeChange(
          event,
          loadfields,
          typeIds?.projectType,
        );
      } else if (type === 'overLoadType') {
        /* loadfields = handleOnOverloadTypeChange(
          event,
          loadfields,
          typeIds?.projectType
        ); */
        //if one time start, then ol time is 2 and olmaxspeed is nmin as default. Here nmin is 400
        if (event === '2' && typeIds?.projectType === 5) {
          loadfields['maxLoadTime'] = '2';
          loadfields['maxSpeed'] = '400';
        } else {
          loadfields['maxLoadTime'] = '60';
        }
        setHasOverloadTypeChanged(true);
        setHasLoadDataChanged(true);

        // if overloadtype is changed to simple cyclic or one time start, multiple overload flag has to be reset
        if (event !== '1') {
          dispatch(resetHasMultipleOverload());
        }
      } else if (type === 'maxLoadTime') {
        if (parseInt(event) >= parseInt(loadfields.dutyCycleTime)) {
          loadfields['dutyCycleTime'] = (parseInt(event) + 1).toString();
        }
      }

      loadfields[type] = event;

      if (type === 'dutyCycleTime') {
        if (parseInt(event) <= parseInt(loadfields.maxLoadTime)) {
          loadfields.dutyCycleTime = (
            parseInt(loadfields.maxLoadTime) + 1
          ).toString();
        }
      }
      dispatch(motorLoadDataSave(loadfields));
    }
  };

  useEffect(() => {
    if (updatedLoadFields?.nMin) {
      const power = parseFloat(updatedLoadFields?.power ?? '0');
      let torqueVal = (
        (power * 60000) /
        (2 * PI * parseFloat(updatedLoadFields?.nBase ?? '0'))
      ).toFixed(1);

      setTorque(parseFloat(torqueVal ?? '0'));
      setHasLoadDataChanged(true);
      //dispatch(motorLoadDataSave(updatedLoadFields));
    }
  }, [updatedLoadFields, dispatch]);

  const handleAddDutyCycleClick = () => {
    if (previousDutyCycleValues.length > 0) {
      setHasDutyCyclePreviousData(true);
    }
    setHasLoadDataChanged(true);
    setViewDutyCycle(true);
  };

  const onAddDutyCycleModalClose = () => {
    setViewDutyCycle(false);
  };

  const onAddDutyCycleModalApply = (hasMultipleOverload: boolean) => {
    if (hasMultipleOverload) {
      let loadfields = { ...updatedLoadFields };
      loadfields['overLoadType'] = '1'; //'Multiform';
      dispatch(motorLoadDataSave(loadfields));
      //saving the multiform change in drive selection as well
      dispatch(driveLoadDataSave(loadfields as unknown as DriveLoadData));
      dispatch(setHasMultipleOverload(hasMultipleOverload));
    }

    setHasOverloadTypeChanged(false);
    setHasLoadDataChanged(false);
    setViewDutyCycle(false);
  };

  useLayoutEffect(() => {
    if (hasLoadDataChanged) {
      let previousTorque = previousDutyCycleValues?.[0]?.torque ?? 0;
      if (
        previousDutyCycleValues?.length > 0 &&
        previousTorque > 0 &&
        previousTorque === torque
      ) {
        setBaseLoadData(previousDutyCycleValues);
        dispatch(saveDutyCycleValuesMotor(previousDutyCycleValues));
      } else {
        let tableData: LoadDataMotorSelection[] = [];
        if (
          motorResultData?.dimension?.dimensionInput?.selectionInputs?.[0]
            ?.motorLoadCycles &&
          motorResultData?.dimension?.dimensionInput?.selectionInputs?.[0]
            ?.motorLoadCycles?.length > 2
        ) {
          motorResultData?.dimension?.dimensionInput?.selectionInputs?.[0]?.motorLoadCycles?.forEach(
            (i, index) => {
              tableData = [
                ...tableData,
                {
                  rowName: index === 0 ? 'Baseload' : 'Overload' + (index + 1),
                  time: parseFloat(i.time ?? '0'),

                  load: parseFloat(i.load ?? '0'),
                  maxSpeed: parseFloat(i.maxSpeed ?? '0'),
                  minSpeed: parseFloat(i.minSpeed ?? '0'),
                  torque: parseFloat(
                    ((parseFloat(i.load ?? '0') * (torque ?? 0)) / 100).toFixed(
                      1,
                    ),
                  ),
                  power:
                    (parseFloat(i.load ?? '0') *
                      parseFloat(updatedLoadFields?.power ?? 0)) /
                    100,
                },
              ];
            },
          );
        } else {
          tableData = [
            {
              rowName: 'Baseload',
              time:
                updatedLoadFields?.dutyCycleTime === 'custom'
                  ? parseFloat(
                      motorResultData?.dimension?.dimensionInput
                        ?.selectionInputs?.[0]?.motorLoadCycles?.[0]?.time ??
                        '0',
                    )
                  : parseFloat(updatedLoadFields?.dutyCycleTime ?? '0') -
                    parseFloat(updatedLoadFields?.maxLoadTime ?? '0'),
              load: 100,
              maxSpeed: parseFloat(updatedLoadFields?.nMax ?? '0'),
              minSpeed: parseFloat(updatedLoadFields?.nMin ?? '0'),
              torque: torque,

              power: parseFloat(updatedLoadFields?.power ?? '0'),
            },
            {
              rowName: 'Overload1',
              time: parseFloat(updatedLoadFields?.maxLoadTime ?? '0'),
              load: parseFloat(updatedLoadFields?.olBaseInput ?? '0'),
              maxSpeed: parseFloat(updatedLoadFields?.nMax ?? '0'),
              minSpeed: parseFloat(updatedLoadFields?.nMin ?? '0'),
              torque: torque,

              power: parseFloat(updatedLoadFields?.power ?? '0'),
            },
          ];
        }
        setBaseLoadData(tableData);
        dispatch(saveDutyCycleValuesMotor(tableData));
        setHasLoadDataChanged(false);
      }
    }
  }, [
    hasLoadDataChanged,
    updatedLoadFields,
    updatedFormFields,
    torque,
    previousDutyCycleValues,
  ]);

  useLayoutEffect(() => {
    if (
      updatedLoadFields?.overLoadType === '1' /* 'Multiform' */ &&
      hasOverloadTypeChanged
    ) {
      if (parseFloat(updatedLoadFields?.power ?? '0') > 0) {
        setViewDutyCycle(true);
        setHasOverloadTypeChanged(false);
      } else {
        notificationManager.error(
          t(I18.motor_selection_notification_power_non_zero_label),
        );
        setHasOverloadTypeChanged(false);
        let loadfields = { ...updatedLoadFields };
        loadfields['overLoadType'] = '0';
        dispatch(motorLoadDataSave(loadfields));
      }
    }
  }, [updatedLoadFields, viewDutyCycle, hasOverloadTypeChanged, t, dispatch]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <CommonuxLoadingIndicator
          indicatorType="circular"
          progressType="indeterminate"
          size="xlarge"
          type="primary-black"
          progressValue={50}
        />
      </div>
    );
  }

  const handleResetApplicationClick = () => {
    sessionStorage.setItem('app-id', workspace.id);
    if (isApplicationModal) {
      dispatch(resetApplication());
      updateProject({
        userToken: session?.user ? session?.user.access_token : undefined,
        projectData: {
          projectId: projectDetails.projectId,
          projectName: projectDetails.projectName,
          jsonFileName: workspace.id,
          projectJson: JSON.stringify({} as Dimension),
          transformerId: selectedTransformerDatas?.trafoId,
          applicationId: workspace?.id,
          bomDetailsJson: JSON.stringify({ AppId: workspace?.id }),
        },
      })
        .then(() => {
          //remove the localstatevalue
          deleteSessionStorageValue();
          //call the redux api to set data as null in redis
          updatePlusCode({
            applicationId: workspace?.id || sessionStorage.getItem('app-id'),
            updatePlusCodes: {
              longtypeCode: '',
              options: [],
            },
          }).then(() => {
            sessionStorage.removeItem('app-id');
          });
        })
        .catch((error) => {
          notificationManager.error(
            'Unable to autosave the project due to: ' + error.toString(),
          );
        });
    } else {
      if (motorResultData.dimension) {
        dispatch(resetMotor());
        dispatch(resetDrive());
        dispatch(resetDriveErrorResponse());
        dispatch(resetMotorErrorResponse());
        const resultData = {
          ...motorResultData,
          dimension: {
            ...motorResultData.dimension,
            dimensionResult: [
              {
                ...motorResultData.dimension?.dimensionResult,
                motorUnit: {},
              },
            ],
          },
        };

        updateProject({
          userToken: session ? session?.user.access_token : undefined,
          projectData: {
            projectId: projectDetails.projectId,
            projectName: projectDetails.projectName,
            jsonFileName: workspace.id,
            projectJson: JSON.stringify({
              dimension: resultData.dimension,
              exceptionType: resultData.exceptionType,
              message: resultData.message,
              transformerId: selectedTransformerDatas?.trafoId,
              applicationId: workspace?.id,
            }),
            bomDetailsJson: JSON.stringify({ AppId: workspace?.id }),
          },
        }).catch((error) => {
          notificationManager.error(
            'Unable to autosave the project due to: ' + error.toString(),
          );
        });
      }
    }
  };

  const handleBackToSelectionClick = () => {
    dispatch(resetApplicationForSelection());
    dispatch(motorParamsDataSave({} as MotorSelectionParamResult));
    dispatch(setEfficiencyTabStatus(false));

    updateProject({
      userToken: session?.user ? session?.user.access_token : undefined,
      projectData: {
        projectId: projectDetails.projectId,
        projectName: projectDetails.projectName,
        jsonFileName: workspace.id,
        projectJson: JSON.stringify({} as Dimension),
        transformerId: selectedTransformerDatas?.trafoId,
        applicationId: workspace?.id,
        bomDetailsJson: JSON.stringify({ AppId: workspace?.id }),
      },
    }).catch((error) => {
      notificationManager.error(
        'Unable to autosave the project due to: ' + error.toString(),
      );
    });

    dispatch(setActiveComponent('selection'));
  };

  return (
    <>
      <div className="motor-selection-header">
        <div className="motor-selection-header-text">
          <Icon slot="icon" size="small" name={'motor'} />
          <div className="motor-selection-header-label">
            {t(I18.motor_selection_title)}
          </div>
        </div>

        <div className={'motor-header-button-container'}>
          <div
            role="button"
            className={`motor-selection-back-button ${
              (isFormLoading || !!!selectionData.power) && 'disabled'
            }`}
            onClick={() => {
              !(isFormLoading || !!!selectionData.power) &&
                setShowSelectionModal(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                !(isFormLoading || !!!selectionData.power) &&
                  setShowSelectionModal(true);
              }
            }}
            hidden={!!!selectionData.power}
            tabIndex={0}
            data-rf="gudied-selection-back-button"
          >
            <Icon slot="icon" size="small" name={'left-arrow'} />
            <span className="motor-reset-label">Guided selection</span>
          </div>
          <div
            className={`motor-selection-restart-button ${
              isFormLoading && 'disabled'
            }`}
            onClick={() => {
              !isFormLoading && setIsMenuOpen(!isMenuOpen);
            }}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                !isFormLoading && setIsMenuOpen(!isMenuOpen);
              }
            }}
            onBlurCapture={() => setIsMenuOpen(false)}
            tabIndex={0}
          >
            <Icon slot="icon" size="small" name={'turn-counter-clockwise'} />
            <span
              className="motor-reset-label"
              data-rf="motor-reset-label"
              onClick={() => setIsMenuOpen(true)}
            >
              Reset
            </span>
            <CommonuxMenu
              className="motor-selection-restart-menu"
              open={isMenuOpen}
              size="small"
            >
              <CommonuxMenuItem
                label="Reset Application"
                data-rf="motor-reset-application"
                size="large"
                onClick={() => {
                  setIsApplicationModal(true);
                  setShowMotorModal(true);
                }}
              ></CommonuxMenuItem>
              <CommonuxMenuItem
                label="Reset Motor"
                data-rf="motor-reset"
                size="large"
                onClick={() => {
                  setIsApplicationModal(false);
                  setShowMotorModal(true);
                }}
              ></CommonuxMenuItem>
            </CommonuxMenu>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="subtitle-container" slot="left">
          <div className="subtitle-header">
            {t(I18.motor_selection_motor_load_title)}
          </div>
          {motorLoadDropdownDataSet.current.map((motorLoadDropdown) => {
            return (
              <div
                className="select-container"
                key={generateKey(motorLoadDropdown.label)}
              >
                <div className="select-header">{motorLoadDropdown.label}</div>
                <SelectContainer
                  size="small"
                  className="select-container-area"
                  disabled={isFormLoading}
                >
                  <Select
                    slot="main"
                    required={true}
                    disabled={isFormLoading}
                    value={
                      updatedLoadFields?.[
                        motorLoadDropdown.accessor as keyof MotorLoadData
                      ] ??
                      motorLoadDataGPDDefaults?.[
                        motorLoadDropdown.accessor as keyof MotorLoadData
                      ]
                    }
                    onSelectChange={(event) =>
                      handleFormDataChange(
                        event.detail.value,
                        motorLoadDropdown.accessor,
                        'load',
                      )
                    }
                  >
                    {motorLoadDropdown.selectInput.map((inputOptions) => {
                      return (
                        <div
                          slot="options-main"
                          key={generateKey(inputOptions.label)}
                        >
                          <SelectOption
                            data-value={inputOptions.value}
                            optionText={inputOptions.label}
                            selected={
                              inputOptions.value ===
                              updatedLoadFields?.[
                                motorLoadDropdown.accessor as keyof MotorLoadData
                              ]
                            }
                          />
                        </div>
                      );
                    })}
                  </Select>
                </SelectContainer>
              </div>
            );
          })}

          <MotorLoadTable isLoading={isFormLoading || isFetching} />

          {updatedLoadFields?.overLoadType !== '2' ? (
            //' not One-time'
            <div className="overload-time-info-container">
              <Input
                label-text={t(
                  I18.motor_selection_motor_load_overload_time_label,
                )}
                className="overload-input"
                size="medium"
                valid={true}
                suffixText={t(I18.time_second_unit_label)}
                onInputChange={(event) =>
                  handleFormDataChange(
                    event.target.value.toString(),
                    'maxLoadTime',
                    'load',
                  )
                }
                required={false}
                inputType={
                  hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                    ? 'text'
                    : 'number'
                }
                value={
                  hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                    ? 'custom'
                    : (updatedLoadFields?.maxLoadTime ??
                      motorLoadDataGPDDefaults.maxLoadTime)
                }
                disabled={
                  isFormLoading ||
                  hasMultipleOverload ||
                  updatedLoadFields?.overLoadType === '1'
                }
              />
              <Input
                label-text={t(
                  I18.motor_selection_motor_load_overload_every_label,
                )}
                className="overload-input"
                size="medium"
                valid={true}
                suffixText={t(I18.time_second_unit_label)}
                onInputChange={(event) =>
                  handleFormDataChange(
                    event.target.value.toString(),
                    'dutyCycleTime',
                    'load',
                  )
                }
                required={false}
                inputType={
                  hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                    ? 'text'
                    : 'number'
                }
                value={
                  hasMultipleOverload || updatedLoadFields?.overLoadType === '1'
                    ? 'custom'
                    : (updatedLoadFields?.dutyCycleTime ??
                      motorLoadDataGPDDefaults.dutyCycleTime)
                }
                disabled={
                  isFormLoading ||
                  hasMultipleOverload ||
                  updatedLoadFields?.overLoadType === '1'
                }
              />
              <div className="add-duty-cycle-button-container">
                <Button
                  type="secondary"
                  className="add-duty-cycle-button"
                  onClick={handleAddDutyCycleClick}
                  disabled={
                    updatedLoadFields?.power === '0' ||
                    updatedLoadFields?.overLoadType === '2' || //'One-time'
                    isFormLoading
                      ? true
                      : false
                  }
                  loading={isFormLoading}
                >
                  <Icon
                    slot="icon"
                    size="small"
                    name="trend-2"
                    disabled={
                      updatedLoadFields?.power === '0' ||
                      updatedLoadFields?.overLoadType === '2' || //'One-time'
                      isFormLoading
                        ? true
                        : false
                    }
                  />
                  {t(I18.motor_selection_add_duty_cycle_button)}
                </Button>
              </div>
            </div>
          ) : (
            <div className="overload-one-time-container">
              <div className="overload-one-time-title-container">
                {t(I18.motor_selection_motor_load_one_time_ol_title)}
              </div>
              <div className="overload-one-time-input-container">
                <Input
                  label-text={t(I18.motor_selection_motor_load_overload_label)}
                  className="overload-one-time-input overload-one-time-input-margin"
                  size="medium"
                  valid={true}
                  suffixText={t(I18.percentage_unit_label)}
                  onInputInput={(event) =>
                    handleFormDataChange(
                      event.target.value.toString(),
                      'olBaseInput', //'oneTimeOverload',
                      'load',
                    )
                  }
                  required={true}
                  inputType="number"
                  value={
                    updatedLoadFields?.olBaseInput ??
                    motorLoadDataGPDDefaults.olBaseInput
                  }
                  disabled={isFormLoading}
                />
                <Input
                  label-text={t(
                    I18.motor_selection_motor_load_one_time_ol_time_label,
                  )}
                  className="overload-one-time-input overload-one-time-input-margin"
                  size="medium"
                  valid={true}
                  suffixText={t(I18.time_second_unit_label)}
                  onInputInput={(event) =>
                    handleFormDataChange(
                      event.target.value.toString(),
                      'maxLoadTime', //'oneTimeOverloadTime',
                      'load',
                    )
                  }
                  required={true}
                  inputType="number"
                  value={
                    updatedLoadFields?.maxLoadTime ??
                    motorLoadDataGPDDefaults.maxLoadTime
                  }
                  disabled={isFormLoading}
                />
                <Input
                  label-text={t(
                    I18.motor_selection_motor_load_one_time_ol_max_speed_label,
                  )}
                  className="overload-one-time-input"
                  size="medium"
                  valid={true}
                  suffixText={t(I18.speed_unit_label)}
                  onInputInput={(event) =>
                    handleFormDataChange(
                      event.target.value.toString(),
                      'maxSpeed', //'oneTimeOverloadMaxSpeed',
                      'load',
                    )
                  }
                  required={true}
                  inputType="number"
                  trailedIcon={false}
                  value={
                    updatedLoadFields?.maxSpeed ??
                    motorLoadDataGPDDefaults.maxSpeed
                  }
                  disabled={isFormLoading}
                />
              </div>
            </div>
          )}
        </div>

        <div className="motor-specification-container">
          <div className="motor-specification-header">
            {t(I18.motor_selection_motor_specification_title)}
          </div>
          {
            <div className="motor-specification-input-text">
              <div className="motor_name_container">
                <Input
                  label-text={t(
                    I18.motor_selection_motor_specifications_name_label,
                  )}
                  size="medium"
                  valid={true}
                  className="motor-specification-input"
                  value={updatedFormFields?.motorName}
                  onInputInput={(event) => {
                    handleFormDataChange(
                      event.detail.value,
                      'motorName',
                      'form',
                    );
                  }}
                  disabled={isFormLoading}
                />
              </div>
              <div className="motors-count-container">
                <Input
                  label-text={t(
                    I18.motor_selection_motor_specifications_no_of_motors_label,
                  )}
                  size="medium"
                  valid={true}
                  required={false}
                  className="motor-specification-input"
                  value={updatedFormFields?.motorsCount ?? '1'}
                  onInputInput={(event) => {
                    handleFormDataChange(
                      event.detail.value,
                      'motorsCount',
                      'form',
                    );
                  }}
                  inputType="number"
                  disabled={isFormLoading}
                />
              </div>
            </div>
          }

          {motorSelectionDropdownDataSet.current.map((dropdown) => {
            return (
              <div
                className="select-container"
                key={generateKey(dropdown.label)}
              >
                <div className="select-header">{dropdown.label}</div>
                {
                  //TO DO: Combo select for efficiency
                  /* dropdown.isMultiSelect ? (
                  <ComboBox
                    size="large"
                    selectedTags={
                      updatedFormFields?.[
                        dropdown.accessor as keyof MotorSelectionData
                      ] as any[]
                    }
                    disabled={isFormLoading}
                    controls="with-clearall-and-dropdown-controls"
                  >
                    {dropdown.selectInput.map((input) => {
                      return (
                        <div slot="options-main" key={generateKey(input.label)}>
                          <ComboboxOption
                            dataValue={input.value}
                            optionText={input.label}
                            selected={
                              updatedFormFields?.[
                                dropdown.accessor as keyof MotorSelectionData
                              ] === undefined
                                ? input.isDefault
                                : updatedFormFields?.efficiencyClass.includes(
                                    input.value
                                  )
                            }
                            onOptionClick={(event) => {
                              handleFormDataChange(
                                event.target.dataset.value ?? '0',
                                dropdown.accessor,
                                'form'
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  </ComboBox>
                ) : */ <SelectContainer
                    size="small"
                    className="select-container-area"
                    multiSelectLimit={3}
                    disabled={isFormLoading || isFetching}
                  >
                    <Select
                      slot="main"
                      required={true}
                      value={
                        updatedFormFields?.[
                          dropdown.accessor as keyof MotorSelectionData
                        ]?.toString() ??
                        dropdown.selectInput[0].value.toString()
                      }
                      onSelectChange={(event) =>
                        handleFormDataChange(
                          event.detail.value,
                          dropdown.accessor,
                          'form',
                        )
                      }
                      disabled={isFormLoading || isFetching}
                    >
                      {dropdown.selectInput.map((input) => {
                        let dropdownAccessor = dropdown?.accessor;
                        if (dropdownAccessor === 'terminalBoxLocation') {
                          dropdownAccessor = dropdownAccessor
                            .replace('.', ' ')
                            .split(' ')
                            .join('')
                            .toLocaleLowerCase();
                        }
                        return (
                          <div
                            slot="options-main"
                            key={generateKey(input.label)}
                          >
                            <SelectOption
                              data-value={input.value}
                              optionText={input.label}
                              selected={
                                input.value ===
                                updatedFormFields?.[
                                  dropdown.accessor as keyof MotorSelectionData
                                ]
                              }
                            />
                          </div>
                        );
                      })}
                    </Select>
                  </SelectContainer>
                }
              </div>
            );
          })}
        </div>
      </div>
      <DutyCycleModalMotor
        modalOpen={viewDutyCycle}
        onClose={onAddDutyCycleModalClose}
        onApplyClick={onAddDutyCycleModalApply}
        baseLoadData={baseLoadData}
        hasPreviousData={hasDutyCyclePreviousData}
      />
      <ManualSelectionModalMotor
        modalOpen={viewManualSelection}
        onClose={onManualSelectionClose}
        isManualSelectionLoading={isManualSelectionLoading}
        onSelectClick={onManualSelectClick}
      />
      <InformationModal
        modalTitle={isApplicationModal ? 'Reset application?' : 'Reset motor?'}
        modalContentText={
          isApplicationModal
            ? 'This will remove the selected drive and motor from the application.'
            : 'This will remove the selected motor from the application.'
        }
        modalOpen={showMotorModal}
        buttonText="Reset"
        onClick={handleResetApplicationClick}
        onClose={() => {
          setShowMotorModal(false);
        }}
      />
      <InformationModal
        modalTitle={'Back to guided selection?'}
        modalContentText={'This will discard your motor & drive sizing.'}
        modalOpen={showSelectionModal}
        buttonText="Go back"
        onClick={handleBackToSelectionClick}
        onClose={() => {
          setShowSelectionModal(false);
        }}
        isInfo={true}
      />
    </>
  );
};
export const MotorSelectionForm = memo(MotorForm);
