const handleDriveInputReqeust = () => {
  return (
    <form
      id="myForm"
      action="https://stage.singledriveconfigurator.fi.abb.com/acs-configurator/services/rest/goselect"
      method="post"
      style={{ display: 'none' }}
    >
      <textarea
        readOnly
        name="CONFIGURATIONREQUEST"
        id="CONFIGURATIONREQUEST"
      />
      <input type="submit" value="GUI start" />
    </form>
  );
};

export default handleDriveInputReqeust;
