import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  PlusCodesObject,
  UpdatePlusCodeObject,
} from '../models/DrivePlusCodeModel';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://goselect-aps-dev-commonservices.azurewebsites.net/api/v1',
  credentials: 'same-origin',
  mode: 'cors',
});

export const drivepluscodeApi = createApi({
  reducerPath: 'drivepluscodeAPI',
  baseQuery,
  endpoints: (builder) => ({
    getpluscodebyid: builder.query<PlusCodesObject | undefined, string>({
      query: (applicationId: string) => {
        return {
          url: `/getpluscodesfromcache?appId=${applicationId}`,
          method: 'GET',
        };
      },
    }),
    postPlusCodeDataById: builder.mutation<any, any>({
      query: (body: UpdatePlusCodeObject) => {
        return {
          url: `/getpluscodesdata?appId=${body.applicationId}`,
          method: 'POST',
          body: body.updatePlusCodes,
        };
      },
    }),
  }),
});

export const { useGetpluscodebyidQuery, usePostPlusCodeDataByIdMutation } =
  drivepluscodeApi;
