import '../styles/narrowmenu.scss';
import { generateKey } from '../utils/GeneralUtils';
import NarrowMenuItem, { NarrowMenuItemProps } from './NarrowMenuItem';

interface NarrowMenuContainerProps {
  isShow: boolean;
  menuItems?: NarrowMenuItemProps[];
  onClickMenuItem?: (menuItem: NarrowMenuItemProps) => void;
}

//Narrow Menu, container, menu items design is inspired from common ux menu.
function NarrowMenuContainer(props: Readonly<NarrowMenuContainerProps>) {
  const { menuItems, isShow, onClickMenuItem } = props;

  const handleOnClickMenuItem = (menuItem: NarrowMenuItemProps) => {
    if (onClickMenuItem) onClickMenuItem(menuItem);
  };

  return (
    <div
      className={`menu-container ${isShow ? 'show' : ''}`}
      data-testid="workspace-menu-tab-items"
    >
      <ul className="content">
        {menuItems?.map((item, idx) => (
          <NarrowMenuItem
            disabled={item.disabled}
            key={generateKey(item.value + item.label + idx)}
            value={item.value}
            label={item.label}
            data={item.data}
            dataRf={item.label.toLowerCase() + '-option'}
            dataTestId={'workspacetab-menuitem'}
            onClick={() => handleOnClickMenuItem(item)}
            isClickable={item.isClickable}
          />
        ))}
      </ul>
    </div>
  );
}

export default NarrowMenuContainer;
